<!-- <div class="theme-card"> -->
<div class="p-3" *ngIf="templateId == 1">
    <!-- <h3 class="text-center">Login </h3> -->
    <!-- <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()"> -->
        <!-- <div class="form-group">
            <label>Email</label> 
            <input type="text" class="form-control" autocomplete="off"  placeholder="Email/Username/Phone" formControlName="emailId" [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }" (keydown.enter)="moveToNextField('password')" #userIdInput>
            <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                <div *ngIf="f.emailId.errors.required">Email is required.</div>
                <div *ngIf="f.emailId.errors.emailId">Please, enter valid email address.</div>
            </div>
        </div>
        <div class="form-group">
            <label >Password</label>
            <input type="password" formControlName="password" autocomplete="off" class="form-control" placeholder="Enter your password"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" (keydown.enter)="moveToNextField('null')" #passwordInput>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required.</div>
                <div *ngIf="f.password.errors.minlength">Password should be 3 character.</div>
            </div>
        </div> -->
        <form [formGroup]="LoginOTPForm">
            <div class="row">
                <div class="col-md-12 justify-content-center">
                    <input type="number" [ngClass]="{ 'is-invalid':  isOTPsubmit && O.phone.errors || isNumberSubmit && O.phone.errors  }" 
                    formControlName="phone" class="form-control" placeholder="Enter Mobile No." />    
                    <div *ngIf="isOTPsubmit && O.phone.errors" class="invalid-feedback">
                        <div *ngIf="O.phone.errors.required">Mobile number is required.</div>
                        <div *ngIf="O.phone.errors.maxlength">Number should be 10 Digits.</div>
                    </div>
                </div>
                <div class="col-md-12 justify-content-center">
                    <div style="text-align: center">
                        <button [disabled]="timerclosed" class="btn btn-primary mt-3" type="button" (click)="generateLoginOtp()">Generate OTP</button>
                    </div>
                </div>
            </div>                
            <div *ngIf="fillotp">
                <p style="color: #bc931f;
                padding: 5px;"  *ngIf="!resendOtplLink">
                   Expire Time: OTP will be expire after <b>{{display}}</b> minutes
                </p>
                <div style="width: 100%;text-align: center;">
                    <p style="color: #bc931f;
                    padding: 5px;"  *ngIf="resendOtplLink">
                       OTP has Expired.
                    </p>
                </div>                    
                <div class="row mt-5">
                    <div class="col-md-3 col-3 form-group">
                        <input id="1" (keyup)="focusNext($event)" formControlName="val1" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val1.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="2" (keyup)="focusNext($event)" formControlName="val2" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val2.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="3" (keyup)="focusNext($event)" formControlName="val3" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val3.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="4" (keyup)="focusNext($event)" formControlName="val4" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val4.errors }">
        
                    </div>                        
                </div>
                <div style="text-align: center;padding: 10px;">
                    <button type="button" (click)="verifyLoginOTP()" class="btn btn-primary">Verify OTP</button>
                </div>
            </div>
        </form>
        
        <!-- <div class="col-md-12">
            <div class="col-md-6" style="text-align: left;">
                <a class="txt-default" (click)="loginPage()" style="color: blue;">Login with Password</a>
            </div>
            <div class="col-md-6" style="text-align: left;">
                <a class="txt-default" (click)="forgetPassword()" routerLinkActive="active" style="color: blue;">Forgot your password?</a>
            </div>            
            
        </div> -->
        <div class="mt-4" style="width: 100%;height: 8vh">
            <a class="txt-default" (click)="loginPage()" style="color: blue;margin-right: 5%;">Login with Password</a>
            <a class="txt-default" (click)="forgetPassword()" routerLinkActive="active" style="color: blue;">Forgot your password?</a>
        </div>        
        <!-- <button class="btn btn-normal mt-3" (click)="loginPage()" [ngStyle]="{'background': (templateId == 1 ? '#0066cb': '#9bc03b')}">Login</button> -->
        
        <!-- <button class="btn btn-normal" type="submit" [ngStyle]="{'background': (templateId == 1 ? '#0066cb': '#9bc03b')}">Login</button> -->
        <!-- <app-login-otp PageType="OTPLogin" [CustomerDetails]="customerDetails"></app-login-otp> -->
        <!-- <a class="float-end txt-default mt-2" (click)="forgetPassword()" routerLinkActive="active" style="color: blue;">Forgot your password?</a> -->
    <!-- </form> -->
    <p class="mt-3">Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
    <!-- <a routerLinkActive="active" class="txt-default pt-3 d-block">Create an Account</a> -->
    <a (click)="opeRegisterPage()" routerLinkActive="active" class="txt-default pt-3 d-block" style="color:blue;font-weight: 700;font-size: 18px;text-decoration: underline;text-align: center;">Get Registered</a>
    <p-dialog [style]="{width: '30vw'}" header="Create account" appendTo="body" [(visible)]="isRegister"  [modal]="false" [draggable]="false"
        class="loginScreen">
        <app-register-model (displayOTPBox)="showOtoBox($event)" [visible]="hidePopups"></app-register-model>
    </p-dialog>
</div>

<div class="p-3" *ngIf="templateId == 2">
    <!-- <h3 class="text-center">Login </h3> -->
    <!-- <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()"> -->
        <!-- <div class="form-group">
            <label>Email</label> 
            <input type="text" class="form-control" autocomplete="off"  placeholder="Email/Username/Phone" formControlName="emailId" [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }" (keydown.enter)="moveToNextField('password')" #userIdInput>
            <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                <div *ngIf="f.emailId.errors.required">Email is required.</div>
                <div *ngIf="f.emailId.errors.emailId">Please, enter valid email address.</div>
            </div>
        </div>
        <div class="form-group">
            <label >Password</label>
            <input type="password" formControlName="password" autocomplete="off" class="form-control" placeholder="Enter your password"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" (keydown.enter)="moveToNextField('null')" #passwordInput>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required.</div>
                <div *ngIf="f.password.errors.minlength">Password should be 3 character.</div>
            </div>
        </div> -->
        <form [formGroup]="LoginOTPForm">
            <div class="row">
                <div class="col-md-12 justify-content-center">
                    <input type="number" [ngClass]="{ 'is-invalid':  isOTPsubmit && O.phone.errors || isNumberSubmit && O.phone.errors  }" 
                    formControlName="phone" class="form-control" placeholder="Enter Mobile No." />    
                    <div *ngIf="isOTPsubmit && O.phone.errors" class="invalid-feedback">
                        <div *ngIf="O.phone.errors.required">Mobile number is required.</div>
                        <div *ngIf="O.phone.errors.maxlength">Number should be 10 Digits.</div>
                    </div>
                </div>
                <div class="col-md-12 justify-content-center">
                    <div style="text-align: center">
                        <button [disabled]="timerclosed" class="btn btn-primary mt-3" type="button" (click)="generateLoginOtp()">Generate OTP</button>
                    </div>
                </div>
            </div>                
            <div *ngIf="fillotp">
                <p style="color: #bc931f;
                padding: 5px;"  *ngIf="!resendOtplLink">
                   Expire Time: OTP will be expire after <b>{{display}}</b> minutes
                </p>
                <div style="width: 100%;text-align: center;">
                    <p style="color: #bc931f;
                    padding: 5px;"  *ngIf="resendOtplLink">
                       OTP has Expired.
                    </p>
                </div>                    
                <div class="row mt-5">
                    <div class="col-md-3 col-3 form-group">
                        <input id="1" (keyup)="focusNext($event)" formControlName="val1" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val1.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="2" (keyup)="focusNext($event)" formControlName="val2" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val2.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="3" (keyup)="focusNext($event)" formControlName="val3" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val3.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="4" (keyup)="focusNext($event)" formControlName="val4" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val4.errors }">
        
                    </div>                        
                </div>
                <div style="text-align: center;padding: 10px;">
                    <button type="button" (click)="verifyLoginOTP()" class="btn btn-primary">Verify OTP</button>
                </div>
            </div>
        </form>
        
        <!-- <div class="col-md-12">
            <div class="col-md-6" style="text-align: left;">
                <a class="txt-default" (click)="loginPage()" style="color: blue;">Login with Password</a>
            </div>
            <div class="col-md-6" style="text-align: left;">
                <a class="txt-default" (click)="forgetPassword()" routerLinkActive="active" style="color: blue;">Forgot your password?</a>
            </div>            
            
        </div> -->
        <div class="mt-4" style="width: 100%;height: 8vh">
            <a class="txt-default" (click)="loginPage()" style="color: blue;margin-right: 5%;">Login with Password</a>
            <a class="txt-default" (click)="forgetPassword()" routerLinkActive="active" style="color: blue;">Forgot your password?</a>
        </div>        
        <!-- <button class="btn btn-normal mt-3" (click)="loginPage()" [ngStyle]="{'background': (templateId == 1 ? '#0066cb': '#9bc03b')}">Login</button> -->
        
        <!-- <button class="btn btn-normal" type="submit" [ngStyle]="{'background': (templateId == 1 ? '#0066cb': '#9bc03b')}">Login</button> -->
        <!-- <app-login-otp PageType="OTPLogin" [CustomerDetails]="customerDetails"></app-login-otp> -->
        <!-- <a class="float-end txt-default mt-2" (click)="forgetPassword()" routerLinkActive="active" style="color: blue;">Forgot your password?</a> -->
    <!-- </form> -->
    <p class="mt-3">Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
    <!-- <a routerLinkActive="active" class="txt-default pt-3 d-block">Create an Account</a> -->
    <a (click)="opeRegisterPage()" routerLinkActive="active" class="txt-default pt-3 d-block" style="color:blue;font-weight: 700;font-size: 18px;text-decoration: underline;text-align: center;">Get Registered</a>
    <p-dialog [style]="{width: '30vw'}" header="Create account" appendTo="body" [(visible)]="isRegister"  [modal]="false" [draggable]="false"
        class="loginScreen">
        <app-register-model (displayOTPBox)="showOtoBox($event)" [visible]="hidePopups"></app-register-model>
    </p-dialog>
</div>

<div class="p-3" *ngIf="templateId == 3">
    <!-- <h3 class="text-center">Login </h3> -->
    <!-- <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()"> -->
        <!-- <div class="form-group">
            <label>Email</label> 
            <input type="text" class="form-control" autocomplete="off"  placeholder="Email/Username/Phone" formControlName="emailId" [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }" (keydown.enter)="moveToNextField('password')" #userIdInput>
            <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                <div *ngIf="f.emailId.errors.required">Email is required.</div>
                <div *ngIf="f.emailId.errors.emailId">Please, enter valid email address.</div>
            </div>
        </div>
        <div class="form-group">
            <label >Password</label>
            <input type="password" formControlName="password" autocomplete="off" class="form-control" placeholder="Enter your password"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" (keydown.enter)="moveToNextField('null')" #passwordInput>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required.</div>
                <div *ngIf="f.password.errors.minlength">Password should be 3 character.</div>
            </div>
        </div> -->
        <form [formGroup]="LoginOTPForm">
            <div class="row">
                <div class="col-md-12 justify-content-center">
                    <input type="number" [ngClass]="{ 'is-invalid': isOTPsubmit && O.phone.errors || isNumberSubmit && O.phone.errors  }" 
                    formControlName="phone" class="form-control" placeholder="Enter Mobile No." />    
                    <div *ngIf="isOTPsubmit && O.phone.errors" class="invalid-feedback">
                        <div *ngIf="O.phone.errors.required">Mobile number is required.</div>
                        <div *ngIf="O.phone.errors.maxlength">Number should be 10 Digits.</div>
                    </div>
                </div>
                <div class="col-md-12 justify-content-center">
                    <div style="text-align: center">
                        <button [disabled]="timerclosed" class="btn btn-primary mt-3 btn-fill-out" type="button" (click)="generateLoginOtp()">Generate OTP</button>
                        <span class="txt-default loginmodal" (click)="forgetPassword()" routerLinkActive="active"><u>Forgot your password?</u></span>
                    </div>
                </div>
            </div>                
            <div *ngIf="fillotp">
                <p style="color: #bc931f;
                padding: 5px;"  *ngIf="!resendOtplLink">
                   Expire Time: OTP will be expire after <b>{{display}}</b> minutes
                </p>
                <div style="width: 100%;text-align: center;">
                    <p style="color: #bc931f;
                    padding: 5px;"  *ngIf="resendOtplLink">
                       OTP has Expired.
                    </p>
                </div>                    
                <div class="row mt-5">
                    <div class="col-md-3 col-3 form-group">
                        <input id="1" (keyup)="focusNext($event)" formControlName="val1" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val1.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="2" (keyup)="focusNext($event)" formControlName="val2" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val2.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="3" (keyup)="focusNext($event)" formControlName="val3" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val3.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="4" (keyup)="focusNext($event)" formControlName="val4" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val4.errors }">
        
                    </div>                        
                </div>
                <div style="text-align: center;padding: 10px;">
                    <button type="button" (click)="verifyLoginOTP()" class="btn btn-primary btn-fill-out">Verify OTP</button>
                </div>
            </div>
        </form>
        
        <!-- <div class="col-md-12">
            <div class="col-md-6" style="text-align: left;">
                <a class="txt-default" (click)="loginPage()" style="color: blue;">Login with Password</a>
            </div>
            <div class="col-md-6" style="text-align: left;">
                <a class="txt-default" (click)="forgetPassword()" routerLinkActive="active" style="color: blue;">Forgot your password?</a>
            </div>            
            
        </div> -->
        <div class="mt-4">
            <!-- <span class="txt-default" (click)="loginPage()">Login with Password</span> -->
            <!-- <span class="txt-default loginmodal" (click)="forgetPassword()" routerLinkActive="active">Forgot your password?</span> -->
        </div>        
        <!-- <button class="btn btn-normal mt-3" (click)="loginPage()" [ngStyle]="{'background': (templateId == 1 ? '#0066cb': '#9bc03b')}">Login</button> -->
        
        <!-- <button class="btn btn-normal" type="submit" [ngStyle]="{'background': (templateId == 1 ? '#0066cb': '#9bc03b')}">Login</button> -->
        <!-- <app-login-otp PageType="OTPLogin" [CustomerDetails]="customerDetails"></app-login-otp> -->
        <!-- <a class="float-end txt-default mt-2" (click)="forgetPassword()" routerLinkActive="active" style="color: blue;">Forgot your password?</a> -->
    <!-- </form> -->
    <p class="mt-3">Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
    <!-- <a routerLinkActive="active" class="txt-default pt-3 d-block">Create an Account</a> -->
    <a (click)="opeRegisterPage()" routerLinkActive="active" class="txt-default pt-3 d-block" style="color:blue;font-weight: 700;font-size: 18px;text-decoration: underline;text-align: center;">Get Registered</a>
    <p-dialog [style]="{width: '30vw'}" header="Create account" appendTo="body" [(visible)]="isRegister"  [modal]="false" [draggable]="false"
        class="loginScreen">
        <app-register-model (displayOTPBox)="showOtoBox($event)" [visible]="hidePopups"></app-register-model>
    </p-dialog>
</div>

<div class="p-3" *ngIf="templateId == 4">
    <!-- <h3 class="text-center">Login </h3> -->
    <!-- <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()"> -->
        <!-- <div class="form-group">
            <label>Email</label> 
            <input type="text" class="form-control" autocomplete="off"  placeholder="Email/Username/Phone" formControlName="emailId" [ngClass]="{ 'is-invalid': submitted && f.emailId.errors }" (keydown.enter)="moveToNextField('password')" #userIdInput>
            <div *ngIf="submitted && f.emailId.errors" class="invalid-feedback">
                <div *ngIf="f.emailId.errors.required">Email is required.</div>
                <div *ngIf="f.emailId.errors.emailId">Please, enter valid email address.</div>
            </div>
        </div>
        <div class="form-group">
            <label >Password</label>
            <input type="password" formControlName="password" autocomplete="off" class="form-control" placeholder="Enter your password"
            [ngClass]="{ 'is-invalid': submitted && f.password.errors }" (keydown.enter)="moveToNextField('null')" #passwordInput>
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                <div *ngIf="f.password.errors.required">Password is required.</div>
                <div *ngIf="f.password.errors.minlength">Password should be 3 character.</div>
            </div>
        </div> -->
        <form [formGroup]="LoginOTPForm" >
            <div class="row">
                <div class="col-md-12 justify-content-center">
                    <input type="number" [ngClass]="{ 'is-invalid':  isOTPsubmit && O.phone.errors || isNumberSubmit && O.phone.errors  }" 
                    formControlName="phone" class="form-control" placeholder="Enter Mobile No." />    
                    <div *ngIf="isOTPsubmit && O.phone.errors" class="invalid-feedback">
                        <div *ngIf="O.phone.errors.required">Mobile number is required.</div>
                        <div *ngIf="O.phone.errors.maxlength">Number should be 10 Digits.</div>
                    </div>
                </div>
                <div class="col-md-12 justify-content-center">
                    <div style="text-align: center">
                        <button [disabled]="timerclosed" class="btn btn-primary mt-3" type="button" (click)="generateLoginOtp()" style="background-color: #1f1f1f;color: white;border: 1px solid;">Generate OTP</button>
                    </div>
                </div>
            </div>                
            <div *ngIf="fillotp">
                <p style="color: #bc931f;
                padding: 5px;"  *ngIf="!resendOtplLink">
                   Expire Time: OTP will be expire after <b>{{display}}</b> minutes
                </p>
                <div style="width: 100%;text-align: center;">
                    <p style="color: #bc931f;
                    padding: 5px;"  *ngIf="resendOtplLink">
                       OTP has Expired.
                    </p>
                </div>                    
                <div class="row mt-5">
                    <div class="col-md-3 col-3 form-group">
                        <input id="1" (keyup)="focusNext($event)" formControlName="val1" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val1.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="2" (keyup)="focusNext($event)" formControlName="val2" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val2.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="3" (keyup)="focusNext($event)" formControlName="val3" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val3.errors }">
        
                    </div>
                    <div class="col-md-3 col-3 form-group">
                        <input id="4" (keyup)="focusNext($event)" formControlName="val4" autocomplete = "off" maxlength="1" type="text" (keypress)="numericOnly($event)" class="form-control" placeholder="*"
                        [ngClass]="{ 'is-invalid': isOTPsubmit && O.val4.errors }">
        
                    </div>                        
                </div>
                <div style="text-align: center;padding: 10px;">
                    <button type="button" (click)="verifyLoginOTP()" class="btn btn-primary" style="background-color: #1f1f1f;color: white;border: 1px solid;">Verify OTP</button>
                </div>
            </div>
        </form>
        
        <!-- <div class="col-md-12">
            <div class="col-md-6" style="text-align: left;">
                <a class="txt-default" (click)="loginPage()" style="color: blue;">Login with Password</a>
            </div>
            <div class="col-md-6" style="text-align: left;">
                <a class="txt-default" (click)="forgetPassword()" routerLinkActive="active" style="color: blue;">Forgot your password?</a>
            </div>            
            
        </div> -->
        <div class="mt-4" style="width: 100%;height: 8vh">
            <a class="txt-default" (click)="loginPage()" style="color: blue;margin-right: 5%;">Login with Password</a>
            <a class="txt-default" (click)="forgetPassword()" routerLinkActive="active" style="color: blue;">Forgot your password?</a>
        </div>        
        <!-- <button class="btn btn-normal mt-3" (click)="loginPage()" [ngStyle]="{'background': (templateId == 1 ? '#0066cb': '#9bc03b')}">Login</button> -->
        
        <!-- <button class="btn btn-normal" type="submit" [ngStyle]="{'background': (templateId == 1 ? '#0066cb': '#9bc03b')}">Login</button> -->
        <!-- <app-login-otp PageType="OTPLogin" [CustomerDetails]="customerDetails"></app-login-otp> -->
        <!-- <a class="float-end txt-default mt-2" (click)="forgetPassword()" routerLinkActive="active" style="color: blue;">Forgot your password?</a> -->
    <!-- </form> -->
    <p class="mt-3">Sign up for a free account at our store. Registration is quick and easy. It allows you to be able to order from our shop. To start shopping click register.</p>
    <!-- <a routerLinkActive="active" class="txt-default pt-3 d-block">Create an Account</a> -->
    <a (click)="opeRegisterPage()" routerLinkActive="active" class="txt-default pt-3 d-block" style="color:blue;font-weight: 700;font-size: 18px;text-decoration: underline;text-align: center;">Get Registered</a>
    <p-dialog [style]="{width: '30vw'}" header="Create account" appendTo="body" [(visible)]="isRegister"  [modal]="false" [draggable]="false"
        class="loginScreen">
        <app-register-model (displayOTPBox)="showOtoBox($event)" [visible]="hidePopups"></app-register-model>
    </p-dialog>
</div>

<verify-otp (submitEvent)="verifyOTP($event);"
    PageType="Login" 
    [CustomerDetails]="customerDetails"
    [DisplayOTPB]="displayOTPB"
    [OTPformGroup]="OTPForm">
</verify-otp>