// import {Component,NgModule, Output, EventEmitter, Input,ViewChild,ElementRef} from '@angular/core';

// import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { ToastrService } from 'ngx-toastr';
// import { ApiintegrationService } from '../../appServices/apiintegration.service';
// import { CommonModule } from '@angular/common';
// import { Router, RouterModule} from '@angular/router';
// import { GlobalService} from '../../appServices/globalService';
// import { DialogModule } from 'primeng/dialog';
// import { FormGroup, FormBuilder, Validators } from '@angular/forms';
// import { AuthenticationService } from '../../appServices/authentication.service';
import {OTPmodule} from '../../includes/verify-otp/verify-otp.component'
// import { ThrowStmt } from '@angular/compiler';
import { RegisterModelModule } from '../register-model/register.component';
// import { LoginOtpModule } from '../../pages/login/login-list/login-otp/login-otp.component';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { AuthenticationService } from '../../appServices/authentication.service';
import { Router } from '@angular/router';
import { GlobalService } from '../../appServices/globalService';
import { ToastrService } from 'ngx-toastr';
import { ApiintegrationService } from '../../appServices/apiintegration.service';

@Component({
    selector: 'login_modal',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class LoginModalComponent implements OnInit{
  isOTPsubmit: boolean;
  isNumberSubmit: boolean;
  display: any = [];
  shortURl: any;
  resendOtplLink: boolean = false;
  displayOTPB:boolean = false;
  fillotp: boolean = false;
  edit = true;
  OTPForm: FormGroup;
  LoginOTPForm: FormGroup;
  timerclosed: boolean = false;
  templateId: any= 1;
  domainURL: any = this.globalService.domainUrl;
@Input('CustomerDetails') customerDetails: any;
@Input('Customstyle') customstyle: any;
displayLoginOTPB: boolean = false;
@Input('PageType') pageType: any;
@Input('visible') public loginPopup;
isRegister: boolean = false;
LoginSuccess
@Output('LoginSuccess') loginSucces: any = new EventEmitter();

  constructor(private appService: ApiintegrationService,private appServices: AuthenticationService,
    private router: Router, private toastr: ToastrService,
    private globalService: GlobalService,private formBuilder: FormBuilder) { 
      this.OTPForm = this.formBuilder.group({
                  val1:['',[Validators.required]],
                  val2 :['',[Validators.required]],
                  val3:['',[Validators.required]],
                  val4:['',[Validators.required]],
                  phone:['',[Validators.required]]
               })
     }

  ngOnInit(): void {
    this.templateId = this.globalService.userInfo.templateId;
    this.shortURl = this.globalService.shortUrl;
    this.LoginOTPForm = this.formBuilder.group({
      val1:['',[Validators.required]],
      val2 :['',[Validators.required]],
      val3:['',[Validators.required]],
      val4:['',[Validators.required]],
      phone:['', [Validators.required, Validators.maxLength(10)]]
     })
  }

  get O() {
    return this.LoginOTPForm.controls;
  }
  showloginotpbox() {
    this.displayLoginOTPB = true;
  }

  loginPage(){
    document.body.scrollTop = 0;
    this.router.navigateByUrl(`/${this.domainURL}/login`);
    this.loginPopup(false);
  }

  forgetPassword(){
    this.router.navigateByUrl(`/${this.domainURL}/forgetPassword`);
    this.loginPopup(false);
    document.body.scrollTop = 0;
  }
        
  ngOnChanges() {
    // if (this.customerDetails) {
    //   this.LoginOTPForm.controls['phone'].setValue(this.customerDetails.mobileNumber || this.customerDetails.mobileNumber);
    //   this.timer(3);
    // }
  }

  numericOnly(event: any): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  generateLoginOtp() {    
    this.isOTPsubmit = true;
    if(this.LoginOTPForm.controls.phone.invalid){
      return;
    }   
    if(this.LoginOTPForm.controls['phone'].valid && this.LoginOTPForm.value.phone.toString().length == 10) {
      const mobile = {
        mobile:this.LoginOTPForm.value.phone.toString()
      }
      this.appServices.generateLoginOTP(mobile).subscribe(res => {
        this.isOTPsubmit = false;
        this.fillotp = true;
        this.timer(3);
        this.toastr.success(res.message);
        this.timerclosed = true;
        this.resendOtplLink = false;
      },err => {
        this.toastr.error('Error occured')
      })
    } else {
      this.isNumberSubmit = true;
    }
  }

  verifyLoginOTP(){
    this.isOTPsubmit = true;
    if(this.LoginOTPForm.invalid){
      return;
    }
    if(this.LoginOTPForm.valid){
      let form = this.LoginOTPForm.value;
      let details = this.globalService.getUserInfo();
      // this.loginPopup(false);
      document.body.scrollTop = 0;
      let params: any = {};
      const otpvalue = parseInt(form.val1 + form.val2 + form.val3 + form.val4)
      params.otp = otpvalue;
      params.mobile = this.LoginOTPForm.value.phone.toString();
      this.appServices.verifyLoginOTP(params).subscribe(res => {
        this.toastr.success(res.message);
        this.customerDetails = res.data.user;
        // this.toastr.success("login Successfully");
        this.LoginOTPForm.controls['phone'].setValue(res.data.mobileNumber);
        if (this.customerDetails.isContactVerify == 1 || this.customerDetails.isContactVerify == 2) {
          details.userInfo = this.customerDetails;
          details.token = res.data.token;
          localStorage.setItem(`${details.shortUrl}User`,JSON.stringify(details));
          this.globalService.vendorInfo.userInfo = details.userInfo;
          this.router.navigateByUrl(`/${this.domainURL}/home`);
          // this.loginPopup(false);
          this.globalService.countUpdate$.next(true);
          this.globalService.loginUserUpdate$.next(this.customerDetails.isContactVerify == 1 || this.customerDetails.isContactVerify === 2);
          window.location.reload();
          this.loginPopup(false);
        }
      }, error => {
        this.toastr.error(error);
      })
    }else {
      this.isOTPsubmit = true;

    }
  }
        opeRegisterPage(){
        this.isRegister = true;
        this.globalService.footerLoginUpdate$.next('change');
        window.scroll(0, 0);
      }

      public hidePopups = (event) => {
        this.isRegister = event;
      }

        verifyOTP(event:boolean){
        this.displayOTPB = false;
        this.loginSucces.emit(true);
        this.globalService.loginUserUpdate$.next(true);
        this.globalService.countUpdate$.next(true);
      }

      showOtoBox(event:any){
        this.customerDetails = event;
        this.OTPForm.controls['phone'].setValue(event.mobileNumber);
        this.displayOTPB = true;
        this.isRegister = false;
      }

  inputsArraY = ['1', '2', '3', '4'];
  public focusNext(e:any) {
    console.log('here');
    try {
      for (let i = 0; i < this.inputsArraY.length; i++) {
        console.log(e.target.id, this.inputsArraY[i], "====", e.target.value)
        if ((e.target.id === this.inputsArraY[i] && e.target.value)) {

          let element: any = document.getElementById(`${this.inputsArraY[i + 1]}`);
          element.focus();
        }
        if ((e.target.id === this.inputsArraY[i]) && (e.target.value == '' || e.target.value == undefined)) {
          console.log(e.target.value, "value")
          let element: any = document.getElementById(`${this.inputsArraY[i - 1]}`);
          element.focus();
        }
      }
    } catch (error) {
      console.log(error);

    }
  }
  
  timer(minute) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    const timer = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if (seconds == 0) {
        this.resendOtplLink = true;
        this.edit = true;
        console.log("finished");
        this.timerclosed = false;
        clearInterval(timer);
      }

    }, 1000);
  }
  //   loginForm:FormGroup;
  //   OTPForm:FormGroup;
  //   submitted:boolean = false; 
  //   shortURl:any
  //   customerDetails:any;
  //   displayOTPB:boolean = false;
  //   LoginSuccess
  //  @Output('LoginSuccess') loginSucces: any = new EventEmitter();
  //  @Input('visible') public loginPopup;
  //  @ViewChild('userIdInput') userIdInput: ElementRef;
  //  @ViewChild('passwordInput') passwordInput: ElementRef;
  //  isRegister: boolean = false;
  //  templateId: any;

  //   constructor(private formBuilder:FormBuilder, private appService:ApiintegrationService, 
  //       private toastrService:ToastrService,
  //       private authenticationService:AuthenticationService,
  //       private globalService:GlobalService, private router: Router) {
  //       this.loginForm = this.formBuilder.group({
  //           emailId: ['', [Validators.required, Validators.email]],
  //           password: ['', [Validators.required, Validators.minLength(6)]]
  //         });

  //         this.OTPForm = this.formBuilder.group({
  //           val1:['',[Validators.required]],
  //           val2 :['',[Validators.required]],
  //           val3:['',[Validators.required]],
  //           val4:['',[Validators.required]],
  //           phone:['',[Validators.required]]
  //        })
  //   }


  //   ngOnInit(){
  //     this.templateId = this.globalService.userInfo.templateId;
  //       this.shortURl = this.globalService.shortUrl;
  //       this.loginForm = this.formBuilder.group({
  //         emailId: ['', [Validators.required]],
  //         password: ['', [Validators.required, Validators.minLength(6)]]
  //       });
  //   }

  //   forgetPassword(){
  //     this.router.navigateByUrl(`/${this.shortURl}/forgetPassword`);
  //     this.loginPopup(false);
  //     window.scroll(0,0);
  //   }

  //   get f() {
  //       return this.loginForm.controls;
  //     }

  //     opeRegisterPage(){
  //       this.isRegister = true;
  //       this.globalService.footerLoginUpdate$.next('change');
  //       window.scroll(0, 0);
  //     }

  //     public hidePopups = (event) => {
  //       this.isRegister = event;
  //     }

  //     onSubmit() {
  //       this.submitted = true;
  //       if (this.loginForm.invalid) {
  //         return;
  //       }
  //       else{
  //         const obj = this.loginForm.value;
  //         let details = this.globalService.getUserInfo();
  //         this.authenticationService.login(obj).subscribe(res => {
  //           if (res) {
  //             this.customerDetails = res.data.user;
  //             details.userInfo = this.customerDetails;
  //              this.loginForm.reset();
  //             // this.toastr.success("login Successfully");
  //             this.OTPForm.controls['phone'].setValue(res.data.mobileNumber);
  //             this.submitted = false;
  //             if(this.customerDetails.isContactVerify==1 || this.customerDetails.isContactVerify==2) {
  //               details.userInfo = this.customerDetails;
  //               details.token = res.data.token;
  //               localStorage.setItem(`${details.shortUrl}User`,JSON.stringify(details));
  //               this.globalService.vendorInfo.userInfo = details.userInfo;
  //               this.loginSucces.emit(true);
  //               this.globalService.profileLogoUpdate$.next(res.data);
  //               this.globalService.countUpdate$.next(true);
  //               this.globalService.loginUserUpdate$.next(this.customerDetails.isContactVerify==1 || this.customerDetails.isContactVerify===2);
  //             }else{
  //                  localStorage.setItem(`${this.shortURl}User`,JSON.stringify(details));
  //                  this.displayOTPB = true;
  //             }
  //           }
  //         }, err => {
  //           console.log('status code ->' + err.status);
  //           this.toastrService.error('Hello world!', 'Toastr fun!');
  //         });
  //       }

  //     }

  //     verifyOTP(event:boolean){
  //       this.displayOTPB = false;
  //       this.loginSucces.emit(true);
  //       this.globalService.loginUserUpdate$.next(true);
  //       this.globalService.countUpdate$.next(true);
  //     }

  //     showOtoBox(event:any){
  //       this.customerDetails = event;
  //       this.OTPForm.controls['phone'].setValue(event.mobileNumber);
  //       this.displayOTPB = true;
  //       this.isRegister = false;
  //     }

  //     moveToNextField(fieldName:string): void{
  //       const control  = this.loginForm.get(fieldName);
  //        if(fieldName == 'userName'){
  //          control?.markAllAsTouched();
  //         this.userIdInput.nativeElement.focus();
  //       }else (fieldName == 'password')
  //         control?.markAllAsTouched();
  //         this.passwordInput.nativeElement.focus();
  //     }

}

@NgModule({
    declarations: [LoginModalComponent],
    // imports: [
    //     FormsModule,
    //     ReactiveFormsModule,
    //     DialogModule,
    //     CommonModule,
    //     RouterModule,
    //     OTPmodule,
    //     RegisterModelModule,
    //     // LoginOtpModule
    // ],
    // providers: [],
    imports: [
      FormsModule,
      ReactiveFormsModule,
      DialogModule,
      CommonModule,
      OTPmodule,
      RegisterModelModule,
    ],
    providers: [],
    exports: [LoginModalComponent]
})

export class LoginModule {

}